import React, { useEffect, useState } from "react";
import { Button, Col, Container, FormCheck, Row, Table } from "react-bootstrap";
import { DateTime } from "luxon";
import DatePicker from "react-date-picker";
import Notification from "../components/Notification";
import PaginationComp from "../components/PaginationComp";
import { listContactRequest } from "../services/contactService";
import Header from "../layouts/Header"

export default function ContactManagement() {
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalItem, setTotalItem] = useState(0);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success"); 
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const [fromDate, setFromDate] = useState(yesterday);
    const [toDate, setToDate] = useState(new Date());
    const [contactType, setContactType] = useState("all");
    const [contacts, setContacts] = useState([]);

    const onContactTypeChange = (event) => {
        if(event.target.checked){
            setContactType(event.target.value);
        }
    }
    const listContact = async() => {
        let fDate = new Date(fromDate);  
        fDate.setHours(0, 0, 0)
        let tDate = new Date(toDate);
        tDate.setHours(23, 59, 59)
        // let fd = (DateTime.fromJSDate(fDate)).toISODate();   
        // let td = DateTime.fromJSDate(tDate).toISODate();  
        const data = await listContactRequest(contactType, fDate, tDate, pageIndex, pageSize);
        if(data.isError){
            displayNotification(data.msg, "danger");
            return;
        }
        setContacts(data.items);
        setTotalItem(data.total);
    }
    useEffect(() => {
        listContact();
    }, [pageIndex])
    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
    }
    const handlePaginationCallback = (size, index) => {
        setPageSize(size);
        setPageIndex(index);
    }
    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row>
                        <Col md={2}>From:</Col>
                        <Col md={2}>
                            <DatePicker value={fromDate} onChange={setFromDate}/>                   
                        </Col>
                        <Col md={2}>To:</Col>
                        <Col md={2}>
                            <DatePicker value={toDate} onChange={setToDate}/>                   
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={2}>
                            Contact type:
                        </Col>
                        <Col md={4}>
                            <FormCheck onChange={onContactTypeChange} type="radio" value="all" label="All" inline defaultChecked name="contact-type"/>
                            <FormCheck onChange={onContactTypeChange} type="radio" value="normuser" label="Normal contact" inline  name="contact-type"/>
                            <FormCheck onChange={onContactTypeChange} type="radio" value="caregiver" label="Caregiver contact" inline  name="contact-type"/>
                        </Col>
                        <Col md={2}>
                            <Button variant="success" onClick={listContact}>Search</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={12}>
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th>Full Name</th>
                                        <th>Phone number</th>
                                        <th>Email</th>
                                        <th>Contact Type</th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contacts &&
                                        <>
                                            {contacts.map((contact) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {contact.fullName}
                                                            </td>
                                                            <td>
                                                                {contact.phoneNumber}
                                                            </td>
                                                            <td>
                                                                {contact.email}
                                                            </td>
                                                            <td>
                                                                {contact.contactType}
                                                            </td>
                                                            <td>
                                                                {(DateTime.fromISO(contact.createdAt)).toLocaleString()}
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1}></Col>
                        <Col md={4}>
                            <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
        </React.Fragment>
    )
}