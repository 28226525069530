import { sendGetRequest } from "./service"

export const listContactRequest = async(contactType, fromDate, toDate, index, size) => {
    let params = {
        pageIndex: index,
        pageSize: size,
        fromDate: fromDate,
        toDate: toDate
    }
    if(contactType !== "all"){
        params.contactType = contactType
    }
    return await sendGetRequest("/api/private/admin/contact", params);
}