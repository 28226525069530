import download from "js-file-download";
import axios from "axios";

export const downloadFile = async (docId) => {
    let params = {
      documentId: docId
    };
    const config = {
        method: 'get',
        url: process.env.REACT_APP_BE_DOMAIN + "/api/document/get",
        headers: {
          'Authorization': sessionStorage.getItem("token"),
        },
        params: params,
        responseType: "blob"
      }
    try{    
        const resp = await axios(config);  
        const disposition = resp.headers['content-disposition'];
        let filename = disposition.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
        filename = decodeURI(filename);
        download(resp.data, filename);
        return {
          isError: false
        }
  
    }catch(error){    
        return {
            isError: true,
            msg: error.response.data.message
        }
    }
  }

  export const uploadImageRequest = async(file, width, height) => {
    const formData = new FormData();
    formData.append(
        "myFile",
        file,
        file.name
    );
    let params = {};
    if(width){
      params.width = width
    }
    if(height){
      params.height = height
    }
    const config = {
        method: 'post',
        url: process.env.REACT_APP_BE_DOMAIN + "/api/file/uploadImage",
        headers: {
          'Authorization': sessionStorage.getItem("token"),
        },
        params: params
      }
    try{
        const resp = await axios.postForm(process.env.REACT_APP_BE_DOMAIN + "/api/file/uploadImage", formData, config);        
        return resp.data;
    }catch(error){    
        return {
            isError: true,
            msg: error.response.data.message
        }
    }
  }