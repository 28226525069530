import React, { useState } from "react";
import { Button, Col, Container, FormControl, FormSelect, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faEdit, faUserPen } from "@fortawesome/free-solid-svg-icons";
import Notification from "../components/Notification"
import PaginationComp from "../components/PaginationComp";

import "../assets/css/rc-table.scss"
import { listUserRequest } from "../services/userService";
import { useEffect } from "react";
import Header from "../layouts/Header"
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";


export default function UserManagement(){
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();

    //search filter
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [showToast, setShowToast] = useState(false);
    const [toastContent, setToastContent] = useState("");   
    const [toastVariant, setToastVariant] = useState("success");
    const [totalItem, setTotalItem] = useState(0);
    const [orderByDate, setOrderByDate] = useState("desc");
    const [userRole, setUserRole] = useState("");

    const columns = [
        {
            title: "STT",
            dataIndex: "stt",
            key: "stt"
        },
        {
            title: "Số điện thoại",
            dataIndex: "phoneNumber",
            key: "phoneNumber"
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email"
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status"
        },
        {
            title: "Ngày tạo",
            dataIndex: "createdAt",
            key: "createdAt"
        },
        {
            title: "Thao tác",
            render: () => {
                return (
                    <div style={{textAlign: "center"}}>
                        <FontAwesomeIcon icon={faUserPen} />
                    </div>
                )
            }
        },
    ];

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const displayNotification = (content, variant) => {
        setToastVariant(variant);    
        setToastContent(content);
        setShowToast(true);
    }
    const closeNotification  = () => {
        setShowToast(false);
    }

    const onSearchClick = async() =>{
        const data = await listUserRequest(email, phoneNumber, userRole, orderByDate, pageIndex, pageSize);
        if(data.isError){
            displayNotification("Can not load users", "danger");
            return;
        }
        let items = [];
        setTotalItem(data.total);
        for(let i = 0; i < data.items.length; i++){
            let roles = [];
            if(data.items[i].Roles){
                for(let j = 0; j < data.items[i].Roles.length; j++){
                    roles.push(data.items[i].Roles[j].roleName)
                }
            }
            items.push({
                stt: i + pageIndex + 1,
                email: data.items[i].email,
                phoneNumber: data.items[i].phoneNumber,
                status: data.items[i].status ? "active" : "inactive",
                createdAt: data.items[i].createdAt,
                key: data.items[i].id,
                role: roles.join(", ")
            })
        }
        setUsers(items);
    }
    useEffect(() => {
        onSearchClick();
    }, [pageIndex])
    const handlePaginationCallback = async (pageSize, pageIndex) => {        
        setPageIndex(pageIndex);
    }

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Container fluid>
                    <Row>
                        <Col md={2}>
                            User Phone number:
                        </Col>
                        <Col md={4}>
                            <FormControl type="number" placeholder="Enter phone number" onChange={e => setPhoneNumber(e.target.value)}/>
                        </Col>
                        <Col md={2}>
                            <Button variant="primary" onClick={e => {
                                if(pageIndex !== 1){
                                    setPageIndex(1)
                                }else{
                                    onSearchClick();
                                }
                            }}>Search</Button>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "10px"}}>
                        <Col md={2}>
                            User's role:
                        </Col>
                        <Col md={3}>
                            <FormSelect defaultValue="" onChange={e => setUserRole(e.target.value)}>
                                <option value="">All</option>
                                <option value="admin">Admin</option>
                                <option value="caregiver">Caregiver</option>
                                <option value="user">User</option>
                            </FormSelect>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={2} style={{fontWeight: "bold"}}>User list:</Col>
                        <Col md={8}></Col>
                        <Col md={2} style={{fontWeight: "bold", textAlign: "right"}}>Total: {totalItem}</Col>
                    </Row>
                    <Row style={{marginTop: "20px"}}>
                        <Col md={12}>
                            <Table bordered striped>
                                <thead className="table-primary">
                                    <tr style={{fontWeight: "bold"}}>
                                        <th>STT</th>
                                        <th>Phone number</th>
                                        <th>Email</th>
                                        <th>Status</th>
                                        <th>Role</th>
                                        <th onClick={e => {
                                            if(orderByDate === "desc"){
                                                setOrderByDate("asc");
                                            }else{
                                                setOrderByDate("desc");
                                            }
                                            if(pageIndex !== 1){
                                                setPageIndex(1)
                                            }else{
                                                onSearchClick()
                                            }
                                        }} style={{cursor: "default"}}>CreatedAt <FontAwesomeIcon icon={orderByDate === "desc" ? faCaretDown : faCaretUp} /></th>
                                        <th style={{textAlign: "center"}}>Edit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users &&
                                        <>
                                            {users.map((user, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{user.stt}</td>
                                                            <td>{user.phoneNumber}</td>
                                                            <td>{user.email}</td>
                                                            <td>{user.status}</td>
                                                            <td>{user.role}</td>
                                                            <td>{DateTime.fromISO(user.createdAt).toISODate(DateTime.DATETIME_SHORT)}</td>
                                                            <td style={{textAlign: "center"}}>
                                                                <FontAwesomeIcon icon={faEdit} cursor={"pointer"} size="lg" onClick={() => navigate(`/user/normal-user/userDetail?id=${user.key}`)} />
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}></Col>
                        <Col md={4}>
                            <PaginationComp total={totalItem} pageSize={pageSize}  callback={handlePaginationCallback} index={pageIndex}/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Notification showToast={showToast} toastContent={toastContent} toastVariant={toastVariant}  closeNotification={closeNotification}/>
        </React.Fragment>
    );
}