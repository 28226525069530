import React, { useEffect, useRef, useState } from "react";
import Header from "../layouts/Header";
import {
  Button,
  Col,
  Container,
  FormControl,
  Row,
  Table,
  Image,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormSelect,
  InputGroup,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faEdit } from "@fortawesome/free-solid-svg-icons";
import { useSearchParams, useNavigate } from "react-router-dom";

import {
  getSubPackageDetailRequest,
  updatePackageItemRequest,
} from "../services/packageService";
import Notification from "../components/Notification";
import { DateTime } from "luxon";
import { ClockLoader } from "react-spinners";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Validator from "fastest-validator";
import { uploadImageRequest } from "../services/documentService";
import DatePicker from "react-date-picker";

const SubPackageDetail = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const [showToast, setShowToast] = useState(false);
  const [toastContent, setToastContent] = useState("");
  const [toastVariant, setToastVariant] = useState("success");

  const [isInit, setIsInit] = useState(false);
  const [subPackageId, setSubPackageId] = useState("");
  const [subPkg, setSubPkg] = useState("");
  const [createdBy, setCreatedBy] = useState("");

  const [loading, setLoading] = useState(false);

  //Modal Edit Dialog
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [editValue, setEditValue] = useState("");
  const [paramsIndex, setParamsIndex] = useState("");
  const [params, setParams] = useState([]);

  const [showUploadImage, setShowUploadImage] = useState(false);
  const uploadImageRef = useRef(null);
  const [image, setImage] = useState(
    "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18a425398cc%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18a425398cc%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.921875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
  );
  const [imageType, setImageType] = useState("small");
  const [uploadedImage, setUploadedImage] = useState("");

  const [unitDialog, setUnitDialog] = useState(false);
  const [unit, setUnit] = useState("hours");

  const [priceDialog, setPriceDialog] = useState(false);
  const [price, setPrice] = useState(0);

  const [statusDialog, setStatusDialog] = useState(false);
  const [status, setStatus] = useState("actived-ready");

  const [activeDateDialog, setActiveDateDialog] = useState(false);
  const [activeDate, setActiveDate] = useState(new Date());

  const [updateDisplayOrder, setUpdateDisplayOrder] = useState(false);
  const [displayIndex, setDisplayIndex] = useState(0)

  const closeActiveDateDialog = async() => {
    setActiveDateDialog(false);
    await getSubPackageDetail();
  }

  const closeStatusDialog = async() => {
    setStatusDialog(false);
    await getSubPackageDetail()
  }

  const closePriceDialog = async() => {
    setPriceDialog(false);
    await getSubPackageDetail()
  }

  const closeUnitDialog = async() => {
    setUnitDialog(false);
    await getSubPackageDetail()
  }

  const closeShowUploadImage = async () => {
    setShowUploadImage(false);
    setImageType("small");
    setUploadedImage("");
    setImage(
      "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22171%22%20height%3D%22180%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20171%20180%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_18a425398cc%20text%20%7B%20fill%3A%23999%3Bfont-weight%3Anormal%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A10pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_18a425398cc%22%3E%3Crect%20width%3D%22171%22%20height%3D%22180%22%20fill%3D%22%23373940%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2259.921875%22%20y%3D%2294.5%22%3E171x180%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E"
    );
    await getSubPackageDetail();
  };

  const displayNotification = (content, variant) => {
    setToastVariant(variant);
    setToastContent(content);
    setShowToast(true);
  };

  const closeNotification = () => {
    setShowToast(false);
    setLoading(false);
  };

  const getSubPackageDetail = async () => {
    setLoading(true);
    const data = await getSubPackageDetailRequest(subPackageId);
    if (data.isError) {
      displayNotification(data.msg, "danger");
      return;
    }
    setSubPkg(data);
    setUnit(data.unit);
    setPrice(data.price)
    setStatus(data.itemStatus)
    setDisplayIndex(data.displayIndex)
    if(data.user){
      setCreatedBy(data.user.fullName ? data.user.fullName : (data.user.email ? data.user.email : (data.User.phoneNumber ? data.User.phoneNumber : data.user.id)))
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isInit) {
      getSubPackageDetail();
    }
  }, [isInit]);

  if (!isInit) {
    if (queryParams.get("id")) {
      setSubPackageId(queryParams.get("id"));
    }
    setIsInit(true);
  }

  //Modal Edit Dialog
  const onCloseEditDialog = async () => {
    setShowEditDialog(false);
    await getSubPackageDetail();
  };

  const onEditClick = (header, value, index) => {
    setModalHeader(header);
    setEditValue(value);
    setParamsIndex(index);
    setShowEditDialog(true);
  };

  const onEditValueChange = (e) => {
    setEditValue(e.target.value);

    let newParams = [null, null, null, null];
    if (paramsIndex === 0) {
      let slug = toSlug(e.target.value);
      slug = slug.substr(0, 24);
      newParams[1] = slug;
      newParams[paramsIndex] = e.target.value;
    } else {
      newParams[paramsIndex] = e.target.value;
    }

    setParams(newParams);
  };

  const onLongDescChange = (e) => {
    setEditValue(e);
    setParams([null, null, null, e]);
  };

  const onUpdateClick = async () => {
    let subPkg = {
      packageName: params[0] || "",
      packageCode: params[1] || "",
      shortDesc: params[2] || "",
      longDesc: params[3] || "",
    };
    const result = checkSubPkg(subPkg);
    if (result.length > 0) {
      let messages = [];
      for (let i = 0; i < result.length; i++) {
        messages.push(result[i].message);
      }
      displayNotification(messages, "danger");
      return;
    }

    let data = null;
    data = await updatePackageItemRequest(
      params[0],
      params[1],
      params[2],
      params[3],
      subPackageId,
      null,
      null
    );

    if (!data.result) {
      displayNotification(`Can not update ${modalHeader}`, "danger");
      return;
    }

    displayNotification(`Update ${modalHeader} successfully`, "success");
    onCloseEditDialog();
  };

  //Validate & slug
  const validator = new Validator();
  const subPackageSchema = {
    packageName: "string",
    shortDesc: "string",
    longDesc: "string",
    packageCode: "string",
  };
  const checkSubPkg = validator.compile(subPackageSchema);

  const itemSchema = {
    itemName: "string",
    shortDesc: "string",
    longDesc: "string",
    itemCode: "string",
  };
  const checkItem = validator.compile(itemSchema);

  const toSlug = (str) => {
    // Chuyển hết sang chữ thường
    str = str.toLowerCase();

    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp

    // Thay ký tự đĐ
    str = str.replace(/[đĐ]/g, "d");

    // Xóa ký tự đặc biệt
    str = str.replace(/([^0-9a-z-\s])/g, "");

    // Xóa khoảng trắng thay bằng ký tự -
    str = str.replace(/(\s+)/g, "-");

    // Xóa ký tự - liên tiếp
    str = str.replace(/-+/g, "-");

    // xóa phần dư - ở đầu & cuối
    str = str.replace(/^-+|-+$/g, "");

    // return
    return str;
  };

  const onFileChange = async (event) => {
    setLoading(true);
    let data = await uploadImageRequest(event.target.files[0]);
    if (data.isError) {
      displayNotification("Can not upload image", "danger");
      return;
    }
    setUploadedImage(data.path);
    setImage(data.path);
    setLoading(false);
  };

  const clickUploadImage = () => {
    uploadImageRef.current.click();
  };
  const saveItemImage = async() => {
    setLoading(true)
    let data = null;
    if(imageType === "small"){
      data = await updatePackageItemRequest(null, null, null, null, subPkg.id, uploadedImage, null);
    }else{
      data = await updatePackageItemRequest(null, null, null, null, subPkg.id, null, uploadedImage);
    }
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    displayNotification("Image is saved", "success");
    closeShowUploadImage()
  }

  const updateItemUnit = async() => {
    setLoading(true);
    const data = await updatePackageItemRequest(null, null, null, null, subPackageId, null, null, unit);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    displayNotification("Item's unit is set", "success");
    closeUnitDialog();
  }

  const updateItemPrice = async() => {
    if(!Number.isInteger(parseInt(price))){
      displayNotification("Price must be an integer number", "danger")
      return;
    }
    const data = await updatePackageItemRequest(null, null, null, null, subPackageId, null, null, null, price);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    displayNotification("Item's price is saved", "success");
    closePriceDialog();
  }

  const updateItemStatus = async() => {
    setLoading(true)
    const data = await updatePackageItemRequest(null, null, null, null, subPackageId, null, null, null, null, status);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    displayNotification("Item's status is changed", "success");
    closeStatusDialog()
  }

  const updateActiveDate = async() => {
    setLoading(true)
    const data = await updatePackageItemRequest(null, null, null, null, subPackageId, null, null, null, null, null, activeDate);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    displayNotification("Item's active date is set", "success");
    closeActiveDateDialog();
  }

  const updateDisplayIndex = async() => {
    setLoading(true);
    const data = await updatePackageItemRequest(null, null, null, null, subPackageId, null, null, null, null, null, activeDate, displayIndex);
    if(data.isError){
      displayNotification(data.msg, "danger");
      return;
    }
    setUpdateDisplayOrder(false);
    setLoading(false);
    await getSubPackageDetail();
  }

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <Container fluid>
          <Row>
            <Col md={12}></Col>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="" onClick={() => navigate(`/service/package`)}>
                    Package management
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <a
                    href=""
                    onClick={() =>
                      navigate(
                        "/service/package/packageDetails?id=" + subPkg.packageId
                      )
                    }
                  >
                    Package detail
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Sub package detail
                </li>
              </ol>
            </nav>
          </Row>

          <Row className="mt-3">
            <b className="fs-5">Sub Package Id: {subPackageId}</b>
          </Row>

          <Row className="mt-3">
            <Col md={12}>
              <Table bordered striped responsive>
                <thead className="table-primary">
                  <tr style={{ fontWeight: "bold" }}>
                    <th>Content</th>
                    <th>Detail</th>
                    <th style={{ textAlign: "center" }}>Edit</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>Sub package name</td>
                    <td>{subPkg.itemName}</td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={() =>
                          onEditClick("Sub-package name", subPkg.itemName, 0)
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Created at</td>
                    <td>
                      {DateTime.fromISO(subPkg.createdAt).toISODate(
                        DateTime.DATE_SHORT
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}></td>
                  </tr>

                  <tr>
                    <td>Created by</td>
                    <td>{createdBy}</td>
                    <td style={{ textAlign: "center" }}></td>
                  </tr>

                  <tr>
                    <td>Sub package code</td>
                    <td>{subPkg.itemCode}</td>
                    <td style={{ textAlign: "center" }}></td>
                  </tr>

                  <tr>
                    <td>Status</td>
                    <td>{subPkg.itemStatus}</td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={e => setStatusDialog(true)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Images (small)</td>
                    <td>
                      {subPkg.smallImage && (
                        <Image
                          width={150}
                          height={150}
                          thumbnail
                          src={subPkg.smallImage}
                        />
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={(e) => {
                          setImageType("small");
                          setShowUploadImage(true);
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Images (large)</td>
                    <td>
                      {subPkg.largeImage && (
                        <Image
                          width={150}
                          height={150}
                          thumbnail
                          src={subPkg.largeImage}
                        />
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={(e) => {
                          setImageType("large");
                          setShowUploadImage(true);
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Short description</td>
                    <td>{subPkg.shortDesc}</td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={() =>
                          onEditClick("Short description", subPkg.shortDesc, 2)
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Long description</td>
                    <td
                      dangerouslySetInnerHTML={{ __html: subPkg.longDesc }}
                    ></td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={() =>
                          onEditClick("Long description", subPkg.longDesc, 3)
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Activated date</td>
                    <td>{subPkg.activeDate}</td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={e => setActiveDateDialog(true)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Ready for booking</td>
                    <td>
                      {subPkg.itemStatus === "actived-ready" ? "Ready" : "Not ready"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                    </td>
                  </tr>

                  <tr>
                    <td>Unit</td>
                    <td>{subPkg.unit}</td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={e => setUnitDialog(true)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Price</td>
                    <td>
                      {subPkg.price || subPkg.price > 0 ? subPkg.price + " VND" : "free"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="lg"
                        cursor={"pointer"}
                        onClick={e => setPriceDialog(true)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Display Order</td>
                    <td>
                        {!updateDisplayOrder &&
                          <>{subPkg.displayIndex}</>
                        }
                        {updateDisplayOrder &&
                          <InputGroup>
                            <FormControl type="number" defaultValue={subPkg.displayIndex} onChange={e => setDisplayIndex(e.target.value)}/>
                            <Button variant="primary" onClick={e => {
                              updateDisplayIndex()
                            }}>Save</Button>
                          </InputGroup>
                        }
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <FontAwesomeIcon icon={faEdit} 
                        size="lg"
                        onClick={e => setUpdateDisplayOrder(true)}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Modal Edit Dialog */}
      <Modal
        show={showEditDialog}
        onHide={onCloseEditDialog}
        size="lg"
        backdrop="static"
      >
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Edit {modalHeader}
        </ModalHeader>

        <ModalBody>
          <Container>
            <Row className="align-items-center">
              <Col md={3}>{modalHeader}:</Col>
              <Col md={9}>
                {modalHeader === "Long description" ? (
                  <ReactQuill
                    theme="snow"
                    onChange={onLongDescChange}
                    value={editValue}
                    className="border mt-3 rounded"
                  />
                ) : (
                  <FormControl
                    type="text"
                    onChange={onEditValueChange}
                    value={editValue}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Button variant="primary" onClick={onUpdateClick}>
            Update
          </Button>

          <Button variant="secondary" onClick={onCloseEditDialog}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        show={showUploadImage}
        onHide={closeShowUploadImage}
        size="lg"
        backdrop="static"
      >
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Upload image
        </ModalHeader>
        <ModalBody>
          <Container fluid="md">
            <Row style={{ marginTop: "10px" }}>
              <Col md={3}>Choose an image:</Col>
              <Col md={5}>
                <Image thumbnail src={image} onClick={clickUploadImage}/>
                <FormControl
                  type="file"
                  style={{ visibility: "hidden" }}
                  ref={uploadImageRef}
                  accept=".jpg, .jpeg, .png"
                  onChange={onFileChange}
                />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={saveItemImage}>
            Save
          </Button>
          <Button variant="secondary" onClick={closeShowUploadImage}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal show={unitDialog} onHide={closeUnitDialog} backdrop="static">
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Set unit for item
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={5}>
              <FormSelect onChange={e => setUnit(e.target.value)} defaultValue={unit}>
                <option value="hours">hours</option>
                <option value="days">days</option>
                <option value="nights">nights</option>
                <option value="packages">packages</option>
              </FormSelect>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={updateItemUnit}>Save</Button>
          <Button variant="secondary" onClick={closeUnitDialog}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <Modal show={priceDialog} onHide={closePriceDialog} backdrop="static">
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Set price for item
        </ModalHeader>
        <ModalBody>
          <Container fluid="md">
            <Row>
              <Col md={3}>Item Price:</Col>
              <Col md={6}>
                <FormControl min={1} step={1} type="number" onChange={e => setPrice(e.target.value)} value={price}/>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={updateItemPrice}>Save</Button>
          <Button variant="secondary" onClick={closePriceDialog}>Cancel</Button>
        </ModalFooter>
      </Modal>
      <Modal show={statusDialog} onHide={closeStatusDialog}>
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>
          Set status for item
        </ModalHeader>
        <ModalBody>
          <FormSelect defaultValue={status} onChange={e => setStatus(e.target.value)}>
            <option value="actived-ready">Ready</option>
            <option value="actived-pending">Pending</option>
            <option value="deactived">Deactive</option>
          </FormSelect>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={updateItemStatus}>Save</Button>
          <Button variant="secondary" onClick={closeStatusDialog}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal show={activeDateDialog} onHide={closeActiveDateDialog}>
        <ModalHeader closeButton style={{ backgroundColor: "#ccd2da" }}>Set active date for item</ModalHeader>
        <ModalBody>
          <DatePicker value={activeDate} onChange={setActiveDate} minDate={new Date()} calendarIcon={<FontAwesomeIcon icon={faCalendar}/>}/>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={updateActiveDate}>Update</Button>
          <Button variant="secondary" onClick={closeActiveDateDialog}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Notification
        showToast={showToast}
        toastContent={toastContent}
        toastVariant={toastVariant}
        closeNotification={closeNotification}
      />

      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          zIndex: 9999,
        }}
      >
        <ClockLoader loading={loading} color="red" />
      </div>
    </React.Fragment>
  );
};

export default SubPackageDetail;
