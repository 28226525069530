import { sendGetRequest, sendPostRequest } from "./service"

export const loadCvDetailRequest = async(cvId) => {
    return await sendGetRequest("/api/private/admin/cv/" + cvId);
}

export const rejectCvRequest = async(cvId) => {
    return await sendPostRequest("put", "/api/private/caregiver/rejectCv", {cvId: cvId});
}
export const setupInterviewRequest = async(cvId, calendar) => {
    let params = {
        cvId: cvId,
        calendar: calendar
    }
    return await sendPostRequest("post", "/api/private/admin/caregiver/setupInterviewCv", params);
}

export const searchCareGiverRequest = async(phoneNumber, index, size) => {
    let params = {
        pageIndex: index,
        pageSize: size
    }
    if(phoneNumber){
        params.phoneNumber = phoneNumber
    }
    return await sendGetRequest("/api/private/admin/caregiver", params);
}
export const confirmCvRequest = async(cvId) => {
    return await sendPostRequest("put", "/api/private/admin/caregiver/cv/confirm", {cvId: cvId})
}