import { sendGetRequest, sendPostRequest } from "./service";

export const listCalendarRequest = async(status, searchById, fromDate, toDate, index, size) => {
    let params = {
        pageIndex: index,
        pageSize: size,
        fromDate: fromDate,
        toDate: toDate,
        calendarId: searchById
    }
    if(status !== "all"){
        params.status = status;
    }
    return await sendGetRequest("/api/private/admin/calendar", params);
}

export const assignCareGiverRequest = async(calendarId, careGiverId) => {
    let params = {
        calendarId: calendarId,
        careGiverId: careGiverId
    }
    return await sendPostRequest("put", "/api/private/admin/calendar/assign", params);
}
export const getCalendarRequest = async(calendarId) => {
    return await sendGetRequest("/api/private/admin/calendar/" + calendarId);
}

export const updateCalendarRequest = async(calendarId, mannualCost, datetime) => {
    let params = {
        calendarId: calendarId
    }
    if(mannualCost){
        params.mannualCost = mannualCost
    }
    if(datetime){
        params.datetime = datetime
    }
    return await sendPostRequest("put", "/api/private/admin/calendar", params);
}

export const cancelCalendarRequest = async(calendarId) => {
    return await sendPostRequest("put", "/api/private/admin/calendar/cancel", {calendarId: calendarId});
}